const buttons = document.querySelectorAll('.Tabs button');
const tabs = document.querySelectorAll('.Tabs__container .Tabs__content');

const setActiveButton = (button) => {
    button.classList.add('active');
};

const clearActiveButton = () => {
    buttons.forEach((button) => button.classList.remove('active'));
};

const toggleTab = (index) => {
    tabs.forEach((tab) => tab.classList.remove('active'));
    return tabs[index] && tabs[index].classList.add('active');
};

buttons.forEach((button, index) => {
    button.addEventListener('click', (ev) => {
        if (!button.classList.contains('forminator-button')) {
            ev.preventDefault();
            clearActiveButton();
            setActiveButton(ev.target);
            toggleTab(index);
        }
    });
});
