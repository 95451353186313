// const expandableLinks = document.querySelectorAll(".InnerNav li.has-children > a");
//
// if (expandableLinks.length > 0) {
//     expandableLinks.forEach((link) => {
//         link.addEventListener("click", (e) => {
//             e.preventDefault();
//             const parent = link.parentNode;
//             parent.classList.toggle("clicked");
//         });
//     });
// }