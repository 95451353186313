const blogFiltersContainer = document.querySelector('#blog-filters');

if (blogFiltersContainer) {
    blogFiltersContainer.addEventListener("change", evt => {
        evt.preventDefault();

        const value = evt.target.value;
        let url;
        if (value === "all") {
            url = `/blog`;
        } else {
            url = evt.target.id === "categories" ? `/category/${value}` : `/tag/${value}`;
        }
        window.location.href = url;
    });
}