import Swiper, { Pagination } from 'swiper';

const swiper = new Swiper('.related-articles', {
    modules: [Pagination],
    slidesPerView: 1,
    spaceBetween: 32,
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
    },
    breakpoints: {
        768: {
            slidesPerView: 2,
        },
        1024: {
            slidesPerView: 3,
        },
    },
});

const relatedArticlesBreakpoint = window.matchMedia('(max-width:768px)');

function addMQListener(mq, callback) {
    if (mq.addEventListener) {
        mq.addEventListener('change', callback);
    } else {
        mq.addListener(callback);
    }
}

const toggleSwiper = (event) => {
    if (event.matches) {
        swiper.enable();
    } else {
        swiper.disable();
    }
};

addMQListener(relatedArticlesBreakpoint, toggleSwiper);
toggleSwiper(relatedArticlesBreakpoint);
