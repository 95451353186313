const MobileMenu = document.getElementById("MobileMenu");

const handleOpeningSubmenus = links => {
    links.forEach(link => {
        link.addEventListener("click", function(e) {
            e.preventDefault();
            e.stopPropagation();
            this.parentNode.querySelector(":scope > ul").classList.add("is-active");
        });
    });
}

const handleCloseButtons = buttons => {
    buttons.forEach(button => {
        button.addEventListener("click", function(e) {
            e.preventDefault();
            e.stopPropagation();
            button.parentNode.classList.remove("is-active");
        });
    });
}

if(MobileMenu) {
    const clickableLinks = MobileMenu.querySelectorAll("li.menu-item-has-children > a");
    handleOpeningSubmenus(clickableLinks);

    const closeButtons = MobileMenu.querySelectorAll(".MobileMenu__goBack");
    handleCloseButtons(closeButtons);
}