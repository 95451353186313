const mobileSearch = document.querySelector(".MobileSearch");

if(mobileSearch) {
    const openBtn = document.querySelector(".MobileSearch__openBtn");
    const closeBtn = document.querySelector(".MobileSearch__closeBtn");

    openBtn.addEventListener("click", () => {
        mobileSearch.classList.add("MobileSearch--open");
    });

    closeBtn.addEventListener("click", () => {
        mobileSearch.classList.remove("MobileSearch--open");
    });
}