const priceList = document.querySelector('.PriceList');

const closeActive = (filteredItems) => {
    filteredItems.forEach((item) => {
        item.classList.remove('active');
    });
};

if (priceList) {
    const items = [...priceList.querySelectorAll('.PriceList__item:not(.no-details)')];

    items.forEach((item) => {
        item.addEventListener('click', () => {
            const active = items.filter((element, index) => {
                return index !== items.indexOf(item);
            });
            closeActive(active);

            item.classList.toggle('active');
        });
    });
}
