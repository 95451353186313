import Swiper, { Autoplay, Pagination, Navigation } from 'swiper';

const learnMore = document.querySelector('.LearnMore');

if (learnMore) {
    const learnMoreSwiper = new Swiper('.LearnMore .swiper', {
        modules: [Autoplay, Pagination],
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.LearnMore .swiper-pagination',
        },
        slidesPerView: 1,
        spaceBetween: 32,
        breakpoints: {
            720: {
                slidesPerView: 2,
            },
        },
    });
}

const selectedPostsContainer = document.querySelector('.SelectedPosts');

if (selectedPostsContainer) {
    const selectedPostsSwiper = new Swiper('.SelectedPosts .swiper', {
        modules: [Autoplay, Pagination],
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.SelectedPosts .swiper-pagination',
        },
        slidesPerView: 1,
        spaceBetween: 32,
        breakpoints: {
            720: {
                slidesPerView: 2,
            },
            1100: {
                slidesPerView: 3,
            }
        },
    });
}

const selectedPackagesContainer = document.querySelector('.SelectedPackagesSlider');

if (selectedPackagesContainer) {
    const selectedPackagesSwiper = new Swiper('.SelectedPackagesSlider .swiper', {
        modules: [Autoplay, Pagination],
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.SelectedPackagesSlider .swiper-pagination',
        },
        slidesPerView: 'auto',
        spaceBetween: 32,
        breakpoints: {
            720: {
                slidesPerView: 3,
            },
            1100: {
                slidesPerView: 4,
            },
        },
    });
}

const selectedAboutPagesContainer = document.querySelector('.SelectedAboutPagesSlider');

if (selectedAboutPagesContainer) {
    const selectedAboutPagesSwiper = new Swiper('.SelectedAboutPagesSlider .swiper', {
        modules: [Autoplay, Pagination],
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.SelectedAboutPagesSlider .swiper-pagination',
        },
        slidesPerView: 1,
        spaceBetween: 32,
        breakpoints: {
            720: {
                slidesPerView: 3,
            },
        },
    });
}

const logosSlider = new Swiper('.logos-slider', {
    modules: [Pagination, Navigation, Autoplay],
    spaceBetween: 24,
    slidesPerView: 2,
    autoplay: {
        speed: 3000
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: '.logos-pagination',
        type: 'bullets',
        clickable: true,
    },

    breakpoints: {
        768: {
            slidesPerView: 5,
        },
        1024: {
            slidesPerView: 8,
        },
    },
});
