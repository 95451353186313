const footerNavs = [...document.querySelectorAll(".Footer__menu-wrapper")];

if (footerNavs.length) {
    footerNavs.forEach(nav => {
        const heading = nav.querySelector(".Footer__menu-heading");
        const parent = heading.closest(".Footer__menu-wrapper");
        const menu = parent.querySelector(".Footer__menu");

        heading.addEventListener("click", () => {
            heading.classList.toggle("active");
            menu.classList.toggle("hidden");
        });
    })
}