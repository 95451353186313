const ACTIVE_CLASS_MENU = 'open';
const ACTIVE_CLASS_HAMBURGER = 'Hamburger--open';

const hamburgerBtn = document.getElementById('toggle');
const navigation = document.getElementById('MobileMenu');

const closeAllNavigations = () => {
    document.querySelectorAll('.MobileMenu ul.is-active').forEach((nav) => nav.classList.remove('is-active'));
};

const handleSetOverflow = () => {
    if (!navigation.classList.contains(ACTIVE_CLASS_MENU)) {
        document.documentElement.style.overflowY = 'auto';
        closeAllNavigations();
    } else {
        document.documentElement.style.overflowY = 'hidden';
    }
};

const toggleMenu = (ev) => {
    ev.currentTarget.classList.toggle(ACTIVE_CLASS_HAMBURGER);
    navigation?.classList.toggle(ACTIVE_CLASS_MENU);

    handleSetOverflow();
};

if(navigation) {
    hamburgerBtn?.addEventListener('click', toggleMenu);
}