import Choices from 'choices.js';

const selects = document.querySelectorAll('#experts-select, #categories, #tags');

selects?.forEach( element => {
    const choices = new Choices(element, {
        searchEnabled: false,
        shouldSort: false,
        classNames: {
            highlightedState: 'highlight-choice' // set to get rid of the default styling
        }
    });
});