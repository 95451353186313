const form = document.getElementById("ExpertsFilters__select");

if(form) {
    let href = `${window.location.origin}${window.location.pathname}`;

    form.addEventListener("change", e => {
        if (e.target.value !== 'all') {
            href = `${href}?term=${e.target.value}`;
        }
        window.location.href = href;
    });
}