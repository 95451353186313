const clearActiveItems = (items) => {
    items.forEach((item) => {
        item.classList.remove('active');
    });
};

const container = document.querySelector('.FAQ');
if (container) {
    container.querySelectorAll('.FAQ__question').forEach((question) => {
        question.addEventListener('click', (e) => {
            const faqItems = [...container.querySelectorAll('.FAQ__item')];
            const [removedItem] = faqItems.splice(
                faqItems.indexOf(e.target.closest('.FAQ__item')),
                1,
            );

            clearActiveItems(faqItems);

            if (removedItem.classList.contains('active')) {
                removedItem.classList.remove('active');
            } else {
                removedItem.classList.add('active');
            }
        });
    });
}
